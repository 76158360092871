<template>
  <div>
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary text-bold">
            Washir Privacy Policy
          </h2>
          <b-card-text class="mb-2 text-bold">
            Updated March 02, 2021
          </b-card-text>

        </b-card-body>
      </b-card>
    </section>

    <div class="container">
      <b-card
        no-body
      >
        <b-card-body>

          <h3 class="mb-1 mt-3">
            Application
          </h3>

          <p>
            Washir and its affiliates ("Washir" or "we") furnishes this Authorized Service Recipient/User Privacy Statement ("Privacy Statement") that applies to persons who use our applications or Services to request auto-detailing, washing or other on-demand services ("Users" or "you"). This Privacy Statement does not apply to information we collect from or about the providers of auto-detailing and washing services, partner auto-detailing and washing companies, or any other persons who use the Washir platform under license (collectively "Service Providers").
          </p>

          <h3 class="mb-1 mt-3">
            Services
          </h3>
          <p>
            Washir provides a technology platform that collects information about you when you use the internet and our mobile applications, websites, and other online products and services (collectively, the "Services"). We also collect information through other interactions and communications you have with us. The Services are provided by Washir, and its affiliate and this Privacy Statement apply to information collected and used by Washir.
          </p>

          <h3 class="mb-1 mt-3">
            Scope
          </h3>
          <p>
            If you utilize the Services as both User and Service Provider, different privacy statements apply to your different uses of the Service.
          </p>

          <h3 class="mb-1 mt-3">
            Collected Information
          </h3>
          <p>
            When you register online to receive our service information, you will need to provide us with your name, address, and email address. When you order for a service through the Washir website, you will also need to provide necessary information in order to reach you in case of problems with the delivery. When you order from the Washir website, we collect information in web and our mobile application.
          </p>
          <p>
            If you are communicating with our customer service, you will need to provide additional information so that you can fully and thoroughly address your questions and concerns.
          </p>

          <p>
            To better understand your preferences, to provide users and subscribers with the most efficient and advanced services, we can ask for your sex, year of birth, interest in sports, commercial preferences and products. Whether or not to provide this information is entirely up to you.
          </p>
          <p>
            We may also collect additional non-personal information (e.g., browser version, IP address) that provides information about the general use of our website. Navigation information to see which areas of our site are most visited and we can improve the quality of online experience by recognizing and providing the most desired features and details of the service.
          </p>

          <h3 class="mb-1 mt-3">
            Using Your Personal Information
          </h3>
          <p>
            This website is exclusively for people living in the United States and providing your personal information that you confirm to reside in the United States. So all of the personal information we get from you will be registered, used and protected by us by the U.S. data protection legislation and this privacy policy.

          </p>
          <p>
            We will primarily use your personal information to provide you with products and services, for example:
          </p>
          <ul style="padding: 0 30px;">
            <li>
              To process and complete your order, including to send you emails to confirm your order and other related e-mails for easy access.
            </li>
            <li>
              To send you information/documents by e-mail or by mail on the services, offers, and news that we think might interest you. This information may relate to the services, offers, and news of Washir other services by any company affiliated with us.
            </li>
          </ul>

          <p>
            The information obtained during an online order is used to improve your web experience by allowing you to obtain faster extraction for purchases and opt-in to receive emails, which provide you with the most recent and important information about our other products and services.
          </p>

          <h3 class="mb-1 mt-3">
            Cookies Usage
          </h3>
          <p>
            A cookie is a small removable data file that is stored by a browser on your PC. Cookies are only used by us to permit you to utilize our website more efficiently. Cookies can place orders on our website and allow us to improve and personalize your surfing and online shopping experience. For example, use cookies as follows:
          </p>
          <ul style="padding: 0 30px;">
            <li>
              Remember shopping basket items
            </li>
            <li>
              Sighted you when you return to our website
            </li>
            <li>
              Analyze visitor behavior
            </li>
          </ul>

          <p>
            You can choose to notify the computer each time a cookie is sent or to disable all cookies. Do it through your browser settings. Please note, however, that you may not be able to access certain areas or features of this website by deleting cookies or disabling.
          </p>

          <p>
            We also work with other companies using Web beacons and other tracking technologies to provide advertising on the Internet. These companies may collect non-personal information about their interactions with our communications, including advertisements and e-mails, about visits to our website. If you are a WEB beacon, we recommend that you remove the existing cookies and follow the steps to disable future cookies. We'll still know how many of our emails are open, and we will automatically receive your IP address,  another access device, or unique identifier of your computer, but we will not be identified as an individual.
          </p>

          <h3 class="mb-1 mt-3">
            Unsubscribing From Email
          </h3>
          <p>
            We have an enterprise policy of not flooding people with email correspondence, but we can stay up to date on what we offer so simply to give you periodic information.
          </p>

          <p>
            You can easily unsubscribe at any time by contacting us or following the instructions that are mentioned in your email. It will take some time to put them out into effect so that you can always receive an email from us for a few weeks after your request is received.
          </p>

          <h3 class="mb-1 mt-3">
            Website Security
          </h3>

          <p>
            The bunker security system on our website is armed with a Sentry system to monitor the activity and prevent unauthorized disclosure of credit card numbers or other personal information. Also, we use a licensed system for credit card authorization and fraud detection. We utilize industry-standard Secure Sockets Layer (SSL) encryption to protect the privacy of your personal information.
          </p>

          <h3 class="mb-1 mt-3">
            Sharing of Information
          </h3>

          <p>
            We may share the information we collect about you as described in this Privacy Statement or as described at the time of collection or sharing, including as follows:
          </p>

          <h3 class="mb-1 mt-3">
            Using Our Services
          </h3>

          <p>
            We may share your information:
          </p>

          <ul style="padding: 0 30px;">
            <li>
              With Service Providers to enable them to provide the Services you request. For example, we share your name, photo (if you provide one), average User rating given by Service Providers, and the locations provided to perform the services;
            </li>
            <li>
              With other persons who are requesting auto-detailing services, like your neighbor or co-worker; and with other people, as directed by you, such as when you want to share the time your auto-detailing or washing services have been completed;
            </li>
            <li>
              With third parties to provide you a service you requested through a partnership or promotional offering made by a third party or us;
            </li>
            <li>
              With the general public if you submit content in a public forum, such as blog comments, social media posts, or other features of our Services that are viewable by the general public;
            </li>
            <li>
              With third parties with whom you choose to let us share information, for example, other apps or websites that integrate with our API or Services, or those with an API or Service with which we integrate; and
            </li>
            <li>
              With your employer (or similar entity) and any necessary third parties engaged by your employer or us (e.g., an account payable service provider related to your employer), if your employer requests services on behalf of its employees and related personnel.
            </li>
          </ul>

          <h3 class="mb-1 mt-3">
            Using Third-Parties services
          </h3>
          <p>
            We may share your information:
          </p>

          <ul style="padding: 0 30px;">
            <li>
              Responding to a request by a competent authority for information, if we believe release and disclosure, in our sole discretion, is by or is otherwise required by, any applicable law, regulation, or legal process;
            </li>
            <li>
              With law enforcement officials, government authorities, or other third parties if we believe your actions are inconsistent with our User agreements, Terms of Service, or policies, or to protect the rights, property, or safety of Washir or others;
            </li>
            <li>
              With Washir subsidiaries and affiliated entities that provide services or conduct data processing on our behalf, or for data centralization and/or logistics purposes;
            </li>
            <li>
              With vendors, consultants, marketing partners, and other service providers who need access to such information to carry out work on our behalf;
            </li>
            <li>
              In connection in the normal course of business and dealings, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or into another company;
            </li>
            <li>
              If we otherwise notify you and you consent to the sharing; and
            </li>
            <li>
              In an aggregated and/or anonymous form which cannot reasonably be used to identify you.
            </li>
          </ul>

          <h3 class="mb-1 mt-3">
            Social Sharing Features
          </h3>

          <p>
            Washir may integrate the Services with sharing features and other related tools which let you share actions you take on our Services with other apps, sites, or media, and vice versa. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the social sharing service. Please refer to the privacy policies of those social sharing services for more information about how they handle the data you provide to or share with them.
          </p>

          <h3 class="mb-1 mt-3">
            Your Choices
          </h3>

          <h3 class="mb-1 mt-3">
            Account Information
          </h3>

          <p>
            You may correct your account information at any time by logging into your online or in-Application account. If you wish to cancel your account, please email us at support@Washir.com. We may retain certain information about you as required by law, or for legitimate business purposes to the extent permitted by law. For instance, if you have a standing credit or debt on your account, or if we believe you have committed fraud or violated our Terms, we may seek to resolve the issue before deleting your information.
          </p>

          <h3 class="mb-1 mt-3">
            Access Rights
          </h3>

          <p>
            Washir will comply with an individual's requests regarding access, correction, and/or deletion of the personal data it stores by applicable law.
          </p>

          <h3 class="mb-1 mt-3">
            Changes to the Statement
          </h3>
          <p>
            We may change this Statement from time to time. If we make significant changes in the way we treat your personal information, or to the Statement, we will provide you notice through the Services or by some other means, such as email. Your continued use of the Services after such notice constitutes your consent to the changes. We encourage you to periodically review the Statement for the latest information on our privacy practices.
          </p>

          <h3 class="mb-1 mt-3">
            Update and Review
          </h3>

          <p>
            Please check the site periodically to see the updates of the information provided here.
          </p>

        </b-card-body>
      </b-card>
    </div>

  </div>

</template>

<script>
import {

  BCard,
  BCardBody,
  BCardText

} from 'bootstrap-vue'

export default {
  name: 'Privacy',
  components: {
    BCard,
    BCardBody,
    BCardText
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
